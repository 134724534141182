import { Grid, IconButton } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../Layout/Loader/Loader";
import { SubmitButton } from "../Layout/SubmitButton/SubmitButton";
import { SinglePageStyle } from "../style/SinglePageStyle";
import {
  AddImageServices,
  GetSingleService,
  servicesReset,
} from "../Redux/Slices/ServicesSlice/ServicesSlice";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { GetAllCompanies } from "../Redux/Slices/CompaniesSlice/CompaniesSlice";
import CircularProgress from "@mui/material/CircularProgress";
import DeleteImage from "./Modals/DeleteImage";
import AddCatalogueModal from "./Modals/AddCatalogueModal";
import { Table } from "../style/TableStyle";
import DeleteCatalogue from "./Modals/DeleteCatalogue";

const SingleService = () => {
  const dispatch = useDispatch();
  const naviage = useNavigate();
  const { id } = useParams();
  const [data, setData] = React.useState();
  const [uploadedFiles, setUploadedFiles] = React.useState([]);
  const [fileLimit, setFileLimit] = React.useState(false);
  let MAX_COUNT = 7;
  const { singleService, servicesStatus, servicesImagesStatus } = useSelector(
    (state) => state.services
  );
  const { allCompanies } = useSelector((state) => state?.companies);
  const { catalogueStatus } = useSelector((state) => state?.catalogue);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openCatalogue, setOpenCatalogue] = React.useState(false);
  const handleOpenCatalogue = () => setOpenCatalogue(true);
  const handleCloseCatalogue = () => setOpenCatalogue(false);

  const [openDeleteCatalogue, setOpenDeleteCatalogue] = React.useState(false);
  const handleOpenDeleteCatalogue = () => setOpenDeleteCatalogue(true);
  const handleCloseDeleteCatalogue = () => setOpenDeleteCatalogue(false);

  const [selected, setSelected] = React.useState();
  const [selectedCalatlouge,setSelectedCalatlouge]=React.useState()
  React.useEffect(() => {
    let functionApi = async () => {
      await dispatch(GetSingleService(id));
      await dispatch(GetAllCompanies());
      await dispatch(servicesReset());
    };
    functionApi();
  }, [dispatch]);

  React.useEffect(() => {
    if (servicesImagesStatus === "success") {
      setUploadedFiles([]);
    }
  }, [servicesImagesStatus]);

  const goBack = () => {
    naviage(-1);
  };

  const handleUploadFiles = (files) => {
    const uploaded = [...uploadedFiles];
    let limitExceeded = false;
    files.some((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);
        if (uploaded.length === MAX_COUNT) setFileLimit(true);
        if (uploaded.length > MAX_COUNT) {
          alert(`You can only add a maximum of ${MAX_COUNT} files`);
          setFileLimit(false);
          limitExceeded = true;
          return true;
        }
      }
    });
    if (!limitExceeded) setUploadedFiles(uploaded);
  };

  const handleFileEvent = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    handleUploadFiles(chosenFiles);
  };

  const deleteImage = (index) => {
    let newArr = [...uploadedFiles];
    newArr.splice(index, 1);
    setUploadedFiles(newArr);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (uploadedFiles.length > 0) {
      const formData = new FormData();
      formData.append("service_id", id);
      uploadedFiles.map((e, i) => {
        formData.append(`image_path[${i}]`, e);
      });
      await dispatch(AddImageServices(formData));
      await dispatch(GetSingleService(id));
      await dispatch(servicesReset());
    }
  };

  const findCompany = (id) => {
    let comany = allCompanies.find((e) => e.id === id);
    return comany;
  };

  return (
    <>
      {servicesStatus === "loading" || catalogueStatus === "loading" ? (
        <>
          <Loader></Loader>
        </>
      ) : (
        <>
          {" "}
          <SinglePageStyle>
            <Grid container columns={12}>
              <Grid item xs={12} mb={2}>
                <SubmitButton onClick={goBack}>Go Back</SubmitButton>
              </Grid>
              <div className="first">
                <div className="content">
                  <Grid item xs={12}>
                    <h4>Single Service</h4>
                  </Grid>
                </div>
              </div>
              <Grid item xs={12}>
                <div className="middle">
                  <div className="content">
                    <Grid container columns={12} spacing={3}>
                      <Grid item xs={12} md={4} lg={3}>
                        {" "}
                        <p className="key">
                          Company name:{" "}
                          <span className="value">
                            {findCompany(singleService?.company_id)?.title}
                          </span>
                        </p>
                      </Grid>
                      <Grid item xs={12} md={4} lg={3}>
                        {" "}
                        <p className="key">
                          Title:{" "}
                          <span className="value">{singleService?.title}</span>
                        </p>
                      </Grid>
                      <Grid item xs={12} md={4} lg={3}>
                        {" "}
                        <div className="image-container">
                          <p className="key">Thumbnail path: </p>
                          <span className="value">
                            <Zoom>
                              <img
                                alt="That Wanaka Tree, New Zealand by Laura Smetsers"
                                src={singleService?.thumbnail_path}
                                width="100"
                              />
                            </Zoom>
                          </span>
                        </div>
                      </Grid>
                      <Grid item xs={12} md={4} lg={3}>
                        {" "}
                        <div className="image-container">
                          <p className="key"> Background path: </p>
                          <span className="value">
                            {singleService?.background_path ? (
                              <>
                                {" "}
                                <Zoom>
                                  <img
                                    alt="background_path"
                                    src={singleService?.background_path}
                                    width="100"
                                  />
                                </Zoom>
                              </>
                            ) : (
                              <span className="value">no Image</span>
                            )}
                          </span>
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        {" "}
                        <p className="key">
                          description :{" "}
                          <span className="value">
                            {singleService?.description}
                          </span>
                        </p>
                      </Grid>
                      <Grid item xs={12} md={4} lg={3}>
                        {" "}
                        <p className="key">
                          Video url:{" "}
                          <span className="value">
                            <a href={singleService?.video_url}>see</a>
                          </span>
                        </p>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className="middle">
                  <div className="content">
                    <div className="container">
                      <h4>Images services</h4>
                      <form onSubmit={handleSubmit}>
                        <IconButton
                          color="primary"
                          aria-label="upload picture"
                          component="label"
                        >
                          <input
                            hidden
                            accept="image/*"
                            type="file"
                            multiple
                            onChange={handleFileEvent}
                            disabled={fileLimit}
                          />
                          <AddIcon></AddIcon>
                        </IconButton>
                        <button type="submit">Upload Images</button>
                      </form>
                      {servicesImagesStatus === "loading" ? (
                        <>
                          {" "}
                          <CircularProgress
                            style={{ padding: "10px" }}
                          ></CircularProgress>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="uploaded-files-list">
                      {uploadedFiles.map((file, i) => (
                        <div key={i}>
                          {file.name}{" "}
                          <IconButton aria-label="delete">
                            <DeleteIcon
                              onClick={() => {
                                deleteImage(i);
                              }}
                            />
                          </IconButton>
                        </div>
                      ))}
                    </div>

                    <div className="images">
                      {singleService?.image_services?.map((img, i) => {
                        return (
                          <Zoom key={i}>
                            <img src={img?.image_path} width="500" />
                            <button
                              className="delete-button"
                              onClick={() => {
                                setSelected(img);
                                handleOpen();
                              }}
                            >
                              <DeleteIcon></DeleteIcon>
                            </button>
                          </Zoom>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className="middle">
                  <div className="content">
                    <div className="container">
                      <h4>Images catalogues</h4>
                      <AddIcon onClick={handleOpenCatalogue}></AddIcon>
                      {servicesImagesStatus === "loading" ? (
                        <>
                          {" "}
                          <CircularProgress
                            style={{ padding: "10px" }}
                          ></CircularProgress>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="images">
                      {singleService?.catalogues?.length > 0 ? (
                        <>
                          {" "}
                          <Grid
                            container
                            columns={12}
                            className="container-catalogues"
                            mt={5}
                          >
                            <Table>
                              <thead>
                                <tr>
                                  <th>Title</th>
                                  <th style={{ textAlign: "center" }}>
                                    Thumbnail path
                                  </th>
                                  <th style={{ textAlign: "center" }}>
                                    Duration
                                  </th>
                                  <th style={{ textAlign: "center" }}>
                                    Video url
                                  </th>
                                  <th style={{ textAlign: "center" }}>
                                    Language
                                  </th>
                                  <th style={{ textAlign: "center" }}>Age</th>
                                  <th style={{ textAlign: "center" }}>
                                    Actions
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {singleService?.catalogues?.map((e, i) => {
                                  return (
                                    <tr key={i}>
                                      <td>{e?.title}</td>
                                      <td
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Zoom>
                                          <img
                                            width="10"
                                            src={e?.thumbnail_path}
                                          />
                                        </Zoom>
                                        {/* <a
                                          href={e?.thumbnail_path}
                                          target="_blank"
                                        >
                                          image
                                        </a> */}
                                      </td>
                                      <td
                                        style={{
                                          textAlign: "center",
                                        }}
                                      >
                                        {e?.duration}
                                      </td>
                                      <td
                                        style={{
                                          textAlign: "center",
                                        }}
                                      >
                                        <a href={e?.video_url} target="_blank">
                                          Video
                                        </a>
                                      </td>
                                      <td
                                        style={{
                                          textAlign: "center",
                                        }}
                                      >
                                        {e?.language}
                                      </td>
                                      <td
                                        style={{
                                          textAlign: "center",
                                        }}
                                      >
                                        {e?.age}
                                      </td>
                                      <td
                                        style={{
                                          textAlign: "center",
                                        }}
                                      >
                                        <DeleteIcon
                                          onClick={() => {
                                            setSelectedCalatlouge(e);
                                            handleOpenDeleteCatalogue();
                                          }}
                                        ></DeleteIcon>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </Table>
                          </Grid>
                        </>
                      ) : (
                        <p>No Images</p>
                      )}
                    </div>
                  </div>
                </div>
              </Grid>
              <div className="middle">
                <div className="content"></div>
              </div>
            </Grid>
          </SinglePageStyle>
        </>
      )}
      <DeleteImage
        open={open}
        handleClose={handleClose}
        handleOpen={handleOpen}
        selected={selected}
      >
      </DeleteImage>
      <AddCatalogueModal
        openCatalogue={openCatalogue}
        handleOpenCatalogue={handleOpenCatalogue}
        handleCloseCatalogue={handleCloseCatalogue}
      ></AddCatalogueModal>
      <DeleteCatalogue
        openDeleteCatalogue={openDeleteCatalogue}
        handleOpenDeleteCatalogue={handleOpenDeleteCatalogue}
        handleCloseDeleteCatalogue={handleCloseDeleteCatalogue}
        selectedCalatlouge={selectedCalatlouge}
      />
    </>
  );
};

export default SingleService;
