import styled from "styled-components";
import { PlatStyle } from "./PlatFormsStyle";

export const SinglePageStyle = styled(PlatStyle)`
  .first {
    width: 100%;
  }
  .middle {
    width: 100%;
  }
  span {
    color: unset !important;
  }
  .images {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    align-items: center;
    img {
      width: 15%;
      margin-right: 15px;
      margin-bottom: 20px;
      display: block;
      max-width: 230px;
      max-height: 95px;
      width: auto;
      height: auto;
      position: relative;
      /* aspect-ratio: 3/2;
      object-fit: contain; */
    }
    .delete-button {
      position: absolute;
      top: 0;
      right: 10px;
      color: red;
      background-color: transparent;
      border: none;
    }
  }
  .key {
    color: black;
  }
  .value {
    color: gray !important;
    padding-left: 2px;
  }

  .image-container {
    display: flex;
    align-items: flex-start;
    ali img {
      padding-left: 10px;
    }
  }
  .container {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    align-items: center;
    svg {
      color: green;
    }
  }
  .container-catalogues {
    width: 100%;
    img {
      width: 100px;
    }
  }
`;
