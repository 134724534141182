import React from "react";
import LeftNavBar from "../components/LeftNavBar/LeftNavBar";

const HomeScreen = () => {
  return (
    <div>
      <LeftNavBar></LeftNavBar>
    </div>
  );
};

export default HomeScreen;
