import {
  Button,
  Grid,
  IconButton,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import AddIcon from "@mui/icons-material/Add";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  AddCatalogue,
  catalogueReset,
} from "../../Redux/Slices/Catalogue/Catalogue";
import { GetSingleService } from "../../Redux/Slices/ServicesSlice/ServicesSlice";
import { Box } from "@mui/system";
import { style } from "../../style/ModalStyle";
import { Co2Sharp, PhotoCamera } from "@mui/icons-material";
import { SubmitButton } from "../../Layout/SubmitButton/SubmitButton";

const AddCatalogueModal = ({ openCatalogue, handleCloseCatalogue }) => {
  const { id } = useParams();
  const [uploadedFiles, setUploadedFiles] = React.useState([]);
  const [fileLimit, setFileLimit] = React.useState(false);
  const [data, setData] = React.useState();
  const dispatch = useDispatch();
  let MAX_COUNT = 7;
  const handleUploadFiles = (files) => {
    const uploaded = [...uploadedFiles];
    let limitExceeded = false;
    files.some((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);
        if (uploaded.length === MAX_COUNT) setFileLimit(true);
        if (uploaded.length > MAX_COUNT) {
          alert(`You can only add a maximum of ${MAX_COUNT} files`);
          setFileLimit(false);
          limitExceeded = true;
          return true;
        }
      }
    });
    if (!limitExceeded) setUploadedFiles(uploaded);
  };

  const handleFileEvent = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    handleUploadFiles(chosenFiles);
  };
  const handleChange = (e) => {
    const { value, name, files } = e.target;
    setData({ ...data, [name]: value });
    if (e.target.files) {
      setData({ ...data, [name]: files[0] });
    }
  };

  const handleSubmitCatalogue = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("service_id", id);
    formData.append("title", data?.title);
    formData.append("thumbnail_path", data?.thumbnail_path);
    formData.append("background_path", data?.background_path);
    formData.append("duration", data?.duration);
    formData.append("video_url", data?.video_url);
    formData.append("language", data?.language);
    formData.append("age", data?.age);
    formData.append("logo_path", data?.logo_path);
    formData.append("description", data?.description);
    let sendData = {
      data: formData,
      id: id,
    };
    await dispatch(AddCatalogue(formData));
    await dispatch(GetSingleService(id));
    await dispatch(catalogueReset());
  };

  console.log("DAta", data);
  return (
    <>
      {" "}
      <Modal
        open={openCatalogue}
        onClose={handleCloseCatalogue}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            align="center"
          >
            Add your catalogue
          </Typography>
          <form onSubmit={handleSubmitCatalogue}>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <Grid container columns={12} spacing={2}>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    label="title"
                    variant="outlined"
                    name="title"
                    onChange={handleChange}
                    fullWidth
                    size="small"
                    value={data?.title}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Description"
                    variant="outlined"
                    name="description"
                    onChange={handleChange}
                    fullWidth
                    size="small"
                    value={data?.description}
                    type="textarea"
                    multiline
                    rows={4}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <Button variant="contained" component="label" fullWidth>
                    Upload thumbnail image
                    <input
                      hidden
                      accept="image/*,.pdf,.docx"
                      type="file"
                      onChange={handleChange}
                      disabled={fileLimit}
                      name="thumbnail_path"
                    />{" "}
                    {data?.thumbnail_path?.name}
                  </Button>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <Button variant="contained" component="label" fullWidth>
                    Upload logo image
                    <input
                      hidden
                      accept="image/*,.pdf,.docx"
                      type="file"
                      onChange={handleChange}
                      disabled={fileLimit}
                      name="logo_path"
                    />{" "}
                    {data?.logo_path?.name}
                  </Button>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <Button variant="contained" component="label" fullWidth>
                    Upload background image
                    <input
                      hidden
                      accept="image/*,.pdf,.docx"
                      type="file"
                      onChange={handleChange}
                      disabled={fileLimit}
                      name="background_path"
                    />{" "}
                    {data?.background_path?.name}
                  </Button>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    label="Duration"
                    variant="outlined"
                    name="duration"
                    onChange={handleChange}
                    fullWidth
                    size="small"
                    type="number"
                    value={data?.duration}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    label="Video url"
                    variant="outlined"
                    name="video_url"
                    onChange={handleChange}
                    fullWidth
                    size="small"
                    value={data?.video_url}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    label="Language"
                    variant="outlined"
                    name="language"
                    onChange={handleChange}
                    fullWidth
                    size="small"
                    value={data?.language}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    label="Age"
                    variant="outlined"
                    name="age"
                    onChange={handleChange}
                    fullWidth
                    size="small"
                    type="number"
                    value={data?.age}
                  />
                </Grid>
                <Grid xs={12} mt={2}>
                  <SubmitButton fullWidth type="submit">
                    Submit Catalouge
                  </SubmitButton>
                </Grid>
              </Grid>
            </Typography>
          </form>{" "}
        </Box>
      </Modal>
    </>
  );
};

export default AddCatalogueModal;
