import { Grid } from "@mui/material";
import React from "react";
import { NavLink, Outlet, useLocation, useParams } from "react-router-dom";
import { TabsStyle } from "../../style/TabsStyle";

const Tabs = () => {
  const location = useLocation();
  const { id } = useParams();
  return (
    <TabsStyle>
      {location?.pathname.includes("single-page") ||
      location?.pathname.includes("single-service") ||
      location?.pathname.includes("create-service") ||
      location?.pathname.includes("create-page") ||
      location?.pathname.includes("create-succes-stories") ||
      location?.pathname.includes("single-succes-stories") ||
      location?.pathname.includes("create-partner") ? (
        <></>
      ) : (
        <>
          <Grid container columns={12}>
            <Grid item xs={12}>
              <div className="first">
                <div className="content">
                  <div className="all-tabs">
                    <ul>
                      <NavLink
                        to={`/main/platforms/container/single-paltform/${id}/pages`}
                      >
                        {" "}
                        <li>Pages</li>{" "}
                      </NavLink>
                      <NavLink
                        to={`/main/platforms/container/single-paltform/${id}/Services`}
                      >
                        {" "}
                        <li>Services</li>{" "}
                      </NavLink>
                      <NavLink
                        to={`/main/platforms/container/single-paltform/${id}/succes-stories`}
                      >
                        {" "}
                        <li>Success Stories</li>{" "}
                      </NavLink>
                      <NavLink
                        to={`/main/platforms/container/single-paltform/${id}/partners`}
                      >
                        {" "}
                        <li>Partners</li>{" "}
                      </NavLink>
                    </ul>
                  </div>
                </div>
              </div>{" "}
            </Grid>
          </Grid>
        </>
      )}
    </TabsStyle>
  );
};

export default Tabs;
