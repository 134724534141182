import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
*{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    font-family: "Inter", sans-serif !important;

}
html*{

    font-family: "Inter", sans-serif !important;

    }
body{
    font-family: "Inter", sans-serif !important;

}
html, body {
    height:100%;
} 

`;
