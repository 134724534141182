import { Box, Grid, Modal, TextField, Typography } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { SubmitButton } from "../../Layout/SubmitButton/SubmitButton";
import {
  EditAddCompany,
  GetAllCompanies,
} from "../../Redux/Slices/CompaniesSlice/CompaniesSlice";
import { style } from "../../style/ModalStyle";

const CompaniesModal = ({ open, handleClose, selectedComany }) => {
  const dispatch = useDispatch();
  const { companiesStatus } = useSelector((state) => state?.companies);

  const [data, setData] = React.useState({
    title: selectedComany?.title,
    id: selectedComany?.id,
  });

  React.useEffect(() => {
    // data.title = selectedComany?.title;
    setData({ ...data, title: selectedComany?.title });
  }, [selectedComany]);

  let sendData = {
    id: selectedComany?.id,
    title: data?.title,
  };

  React.useEffect(() => {
    if (companiesStatus === "success") {
      handleClose();
    }
  }, [companiesStatus]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    await dispatch(EditAddCompany(sendData));
    await dispatch(GetAllCompanies());
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            align="center"
          >
            Edit Company title
          </Typography>
          <form onSubmit={handleSubmit}>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <Grid container columns={12} spacing={2}>
                <Grid item xs={12}>
                  {" "}
                  <TextField
                    required
                    id="outlined-required"
                    label="Company title"
                    size="small"
                    fullWidth
                    value={data?.title}
                    onChange={(e) => {
                      setData({ ...data, title: e.target.value });
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SubmitButton type="submit" fullWidth>
                    Submit
                  </SubmitButton>
                </Grid>
              </Grid>
            </Typography>
          </form>
        </Box>
      </Modal>
    </>
  );
};

export default React.memo(CompaniesModal);
