import { Grid } from "@mui/material";
import React from "react";
import LoginForm from "../components/LoginForm";
import Lottie from "lottie-react";
import cs from "../assets/lottie/cs.json";
const LoginPage = () => {
  return (
    <Grid
      container
      columns={12}
      justifyContent="center"
      alignItems="center"
      style={{ height: "100vh" }}
    >
      <Grid item lg={6} md={6} xs={12}>
        <Lottie loop={true} animationData={cs} />
      </Grid>
      <Grid item lg={6} md={6} xs={12}>
        <LoginForm></LoginForm>
      </Grid>
    </Grid>
  );
};

export default LoginPage;
