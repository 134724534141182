import React from "react";
import { Link, useLocation } from "react-router-dom";
import { SubmitButton } from "../Layout/SubmitButton/SubmitButton";
import { PlatStyle } from "../style/PlatFormsStyle";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../Layout/Loader/Loader";
import {
  AddCompany,
  GetAllCompanies,
} from "../Redux/Slices/CompaniesSlice/CompaniesSlice";
import { Table } from "../style/TableStyle";
import CompaniesModal from "./Modals/CompaniesModal";
import ComapnyDelete from "./Modals/ComapnyDelete";
import { Grid, TextField } from "@mui/material";
const AllPlatforms = () => {
  const dispatch = useDispatch();
  const [data, setData] = React.useState();
  const handleSubmit = async (e) => {
    e.preventDefault();
    await dispatch(AddCompany(data));
    await dispatch(GetAllCompanies());
  };
  const { allCompanies, companiesStatus } = useSelector(
    (state) => state.companies
  );
  const [selectedComany, setSelectedCompany] = React.useState();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openDelete, setOpenDelete] = React.useState(false);
  const handleOpenDelete = () => setOpenDelete(true);
  const handleCloseDelete = () => setOpenDelete(false);

  console.log("selectedComany", selectedComany);

  React.useEffect(() => {
    dispatch(GetAllCompanies());
  }, []);

  return (
    <>
      <PlatStyle>
        <form onSubmit={handleSubmit}>
          <Grid container columns={12}>
            <Grid item xs={12}>
              <div className="first">
                <div className="content">
                  <TextField
                    required
                    id="outlined-required"
                    label="Company title"
                    size="small"
                    onChange={(e) => {
                      setData({ ...data, title: e.target.value });
                    }}
                  />

                  <SubmitButton style={{ margin: "0 10px" }} type="submit">
                    Add
                  </SubmitButton>
                </div>
              </div>
            </Grid>
            {companiesStatus === "loading" ? (
              <>
                <Grid item xs={12}>
                  <Loader></Loader>
                </Grid>
              </>
            ) : (
              <>
                {" "}
                <Grid item xs={12}>
                  <div className="middle">
                    <p className="content">
                      Number of all Companies:{" "}
                      <span>{allCompanies?.length}</span>
                    </p>
                  </div>
                  <div className="second">
                    <div className="content">
                      <Table>
                        <thead>
                          <tr>
                            <th>Company title</th>
                            {/* <th style={{ textAlign: "center" }}>
                              What to show
                            </th> */}
                            <th style={{ textAlign: "center" }}>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {allCompanies?.map((e, i) => {
                            return (
                              <tr key={i}>
                                <td>{e?.title}</td>

                                <td style={{ textAlign: "center" }}>
                                  <ModeEditIcon
                                    onClick={() => {
                                      setSelectedCompany(e);
                                      handleOpen();
                                    }}
                                  ></ModeEditIcon>
                                  <DeleteIcon
                                    onClick={() => {
                                      setSelectedCompany(e);
                                      handleOpenDelete();
                                    }}
                                  ></DeleteIcon>
                                  <Link
                                    to={`container/single-paltform/${e?.id}/pages`}
                                  >
                                    <VisibilityIcon></VisibilityIcon>
                                  </Link>{" "}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </Grid>
              </>
            )}
          </Grid>
        </form>
      </PlatStyle>
      <CompaniesModal
        open={open}
        handleClose={handleClose}
        selectedComany={selectedComany}
      ></CompaniesModal>
      <ComapnyDelete
        ComapnyDelete={ComapnyDelete}
        handleOpenDelete={handleOpenDelete}
        handleCloseDelete={handleCloseDelete}
        selectedComany={selectedComany}
        openDelete={openDelete}
      ></ComapnyDelete>
    </>
  );
};

export default AllPlatforms;
