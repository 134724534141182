import styled from "styled-components";
import React from "react";
import Lottie from "lottie-react";
import adgroupLoader from "../Loader/adgroupLoader.json";
const Loader = () => {
  return (
    <LoaderStyle>
      <Lottie
        loop={true}
        animationData={adgroupLoader}
        style={{ width: "300px", height: "300px" }}
      />
    </LoaderStyle>
  );
};
const LoaderStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  z-index: 9999;
  position: absolute;
  top: 0;
  left: 0;
  /* background-color: rgba(0, 0, 0, 0.1); */
`;

export default Loader;
