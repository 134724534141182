import { Button, Grid, TextField } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import Loader from "../../Layout/Loader/Loader";
import { SubmitButton } from "../../Layout/SubmitButton/SubmitButton";
import { SinglePageStyle } from "../../style/SinglePageStyle";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import {
  AddSuccessStory,
  SucessStoryReset,
} from "../../Redux/Slices/SuccessStories/SuccessStories";
import {
  AddPartner,
  partnerReset,
} from "../../Redux/Slices/PartnerSlice/Partner";

const CreateParnter = () => {
  const naigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { partnerStatus } = useSelector((state) => state.partner);

  let company_id = location.pathname.split("/")[5];

  const [data, setData] = React.useState();
  const goBack = () => {
    naigate(-1);
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setData({ ...data, [name]: value });
  };

  React.useEffect(() => {
    if (partnerStatus === "success") {
      Navigate(-1);
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("title", data?.title);
    formData.append("thumbnail_path", data?.thumbnail_path);
    formData.append("company_id", company_id);
    await dispatch(AddPartner(formData));
    await dispatch(partnerReset());
  };
  return (
    <div>
      <SinglePageStyle>
        <form onSubmit={handleSubmit}>
          <Grid container columns={12}>
            <div className="first">
              <div className="content">
                <Grid item xs={12}>
                  <h4>Partner Form</h4>
                </Grid>
              </div>
            </div>
            <div className="middle">
              <div className="content">
                {partnerStatus === "loading" ? (
                  <>
                    <Loader></Loader>
                  </>
                ) : (
                  <>
                    {" "}
                    <Grid container columns={12} spacing={3}>
                      <Grid item xs={12}>
                        <TextField
                          required
                          fullWidth
                          id="outlined-required"
                          label="Title"
                          size="small"
                          name="title"
                          onChange={handleChange}
                        />
                      </Grid>
                      {/* <Grid item xs={12}>
                        <TextField
                          required
                          fullWidth
                          id="outlined-required"
                          label="Client"
                          size="small"
                          name="client"
                          onChange={handleChange}
                        />
                      </Grid> */}
                      <Grid item xs={12}>
                        <Button
                          variant="contained"
                          component="label"
                          fullWidth
                          startIcon={<PhotoCamera />}
                        >
                          Upload
                          <input
                            hidden
                            accept="image/*"
                            type="file"
                            onChange={(e) => {
                              setData({
                                ...data,
                                thumbnail_path: e.target.files[0],
                              });
                            }}
                          />
                          <span style={{ color: "white", margin: "0 10px" }}>
                            {" "}
                            {data?.thumbnail_path?.name}
                          </span>
                        </Button>
                      </Grid>
                      {/* <Grid item xs={12}>
                        <TextField
                          required
                          fullWidth
                          id="outlined-required"
                          label="Description"
                          size="small"
                          name="description"
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          required
                          fullWidth
                          id="outlined-required"
                          label="Web url"
                          size="small"
                          name="web_url"
                          onChange={handleChange}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          required
                          fullWidth
                          id="outlined-required"
                          label="video url"
                          size="small"
                          name="video_url"
                          onChange={handleChange}
                        />
                      </Grid> */}

                      <Grid item xs={12}>
                        <SubmitButton type="submit" fullWidth>
                          Submit
                        </SubmitButton>
                      </Grid>
                    </Grid>
                  </>
                )}
              </div>
            </div>
          </Grid>
        </form>
      </SinglePageStyle>
    </div>
  );
};

export default CreateParnter;
