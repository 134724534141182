import { Box, Modal, Typography } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { CancelButton } from "../../Layout/DeleteButton/CancelButton";
import { DeleteButton } from "../../Layout/DeleteButton/DeleteButton";
import { DeleteCataloguee } from "../../Redux/Slices/Catalogue/Catalogue";
import { GetSingleService } from "../../Redux/Slices/ServicesSlice/ServicesSlice";
import { style } from "../../style/ModalStyle";

const DeleteCatalogue = ({
  openDeleteCatalogue,
  handleCloseDeleteCatalogue,
  selectedCalatlouge,
}) => {
  const dispatch = useDispatch();
  const { id } = useParams();

  console.log("selectedCalatlouge", selectedCalatlouge?.id);
  const handleDelete = async (e) => {
    await dispatch(DeleteCataloguee(selectedCalatlouge?.id));
    await dispatch(GetSingleService(id));
  };
  return (
    <div>
      <Modal
        open={openDeleteCatalogue}
        onClose={handleCloseDeleteCatalogue}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            align="center"
          >
            Are you sure you want to delete this catalogue ?
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2 }}
            align="center"
          >
            <CancelButton onClick={handleCloseDeleteCatalogue}>
              Cancel
            </CancelButton>{" "}
            <DeleteButton onClick={handleDelete}>Delete</DeleteButton>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

export default DeleteCatalogue;
