import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { success_story, success_story_images } from "../../../Api/Api";

export const GetAllSuccessStories = createAsyncThunk(
  "SuccessStory/GET",
  async (_, thunkAPI) => {
    try {
      var user = JSON.parse(localStorage.getItem("adGroup"));
      const headers = {
        Accept: "application/json",
        XMLHttpRequest: "XMLHttpRequest",
        Authorization: `Bearer ${user?.access_token}`,
      };
      return await axios({
        method: "GET",
        baseURL: `${success_story}`,
        headers: headers,
      }).then((res) => {
        return res.data.data;
      });
    } catch (error) {
      const message = error.response.data.message;
      console.log("message", message);
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const AddSuccessStory = createAsyncThunk(
  "SuccessStory/create",
  async (data, thunkAPI) => {
    try {
      var user = JSON.parse(localStorage.getItem("adGroup"));
      console.log("user", user);
      const headers = {
        Accept: "application/json",
        XMLHttpRequest: "XMLHttpRequest",
        Authorization: `Bearer ${user?.access_token}`,
      };
      return await axios({
        method: "POST",
        baseURL: `${success_story}`,
        headers: headers,
        data: data,
      }).then((res) => {
        toast.success(`SuccessStory has been Created`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return res.data;
      });
    } catch (error) {
      const message = error.response.data.message;
      console.log("message", message);
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const DeleteSuccessStories = createAsyncThunk(
  "SuccessStory/delete",
  async (data, thunkAPI) => {
    try {
      var user = JSON.parse(localStorage.getItem("adGroup"));
      const headers = {
        Accept: "application/json",
        XMLHttpRequest: "XMLHttpRequest",
        Authorization: `Bearer ${user?.access_token}`,
      };
      return await axios({
        method: "DELETE",
        baseURL: `${success_story}/${data}`,
        headers: headers,
      }).then((res) => {
        toast.success(`SuccessStory has been deleted`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return res.data;
      });
    } catch (error) {
      const message = error.response.data.message;
      console.log("message", message);
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const EditAddSuccessStory = createAsyncThunk(
  "SuccessStory/edit",
  async (data, thunkAPI) => {
    try {
      var user = JSON.parse(localStorage.getItem("adGroup"));
      console.log("data in request", data);
      const headers = {
        Accept: "application/json",
        XMLHttpRequest: "XMLHttpRequest",
        Authorization: `Bearer ${user?.access_token}`,
      };
      return await axios({
        method: "PATCH",
        baseURL: `${success_story}/${data?.id}`,
        headers: headers,
        data: data,
      }).then((res) => {
        toast.success(`SuccessStory has been edited`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return res.data;
      });
    } catch (error) {
      const message = error.response.data.message;
      console.log("message", message);
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const GetSingleSuccessStory = createAsyncThunk(
  "SuccessStory/GETSingle",
  async (data, thunkAPI) => {
    try {
      var user = JSON.parse(localStorage.getItem("adGroup"));
      const headers = {
        Accept: "application/json",
        XMLHttpRequest: "XMLHttpRequest",
        Authorization: `Bearer ${user?.access_token}`,
      };
      return await axios({
        method: "GET",
        baseURL: `${success_story}/${data}`,
        headers: headers,
      }).then((res) => {
        return res.data.data;
      });
    } catch (error) {
      const message = error.response.data.message;
      console.log("message", message);
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const AddSuccessStoryImages = createAsyncThunk(
  "SuccessStory/AddImages",
  async (data, thunkAPI) => {
    try {
      var user = JSON.parse(localStorage.getItem("adGroup"));
      console.log("user", user);
      const headers = {
        Accept: "application/json",
        XMLHttpRequest: "XMLHttpRequest",
        Authorization: `Bearer ${user?.access_token}`,
      };
      return await axios({
        method: "POST",
        baseURL: `${success_story_images}`,
        headers: headers,
        data: data,
      }).then((res) => {
        toast.success(`SuccessStory has been Created`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return res.data;
      });
    } catch (error) {
      const message = error.response.data.message;
      console.log("message", message);
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const DeleteSuccessStoryImages = createAsyncThunk(
  "SuccessStory/AddImages",
  async (data, thunkAPI) => {
    try {
      var user = JSON.parse(localStorage.getItem("adGroup"));
      console.log("user", user);
      const headers = {
        Accept: "application/json",
        XMLHttpRequest: "XMLHttpRequest",
        Authorization: `Bearer ${user?.access_token}`,
      };
      return await axios({
        method: "DELETE",
        baseURL: `${success_story_images}/${data}`,
        headers: headers,
      }).then((res) => {
        toast.success(`Image has been Deleted`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return res.data;
      });
    } catch (error) {
      const message = error.response.data.message;
      console.log("message", message);
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const SuccessStorySlice = createSlice({
  name: "SucessStory",
  initialState: {
    successStoriesStatus: "",
    error: "",
    allSuccessStories: [],
    pageData: "",
    singleSuccessStory: "",
    successImagesStatus: "",
  },
  reducers: {
    SucessStoryReset: (state) => {
      state.successStoriesStatus = "";
      state.successImagesStatus = "";
    },
    saveData: (state, { payload }) => {
      state.servicesData = payload;
    },
  },
  extraReducers: {
    [AddSuccessStory.pending]: (state, { payload }) => {
      state.successStoriesStatus = "loading";
    },
    [AddSuccessStory.fulfilled]: (state, { payload }) => {
      state.successStoriesStatus = "success";
    },
    [AddSuccessStory.rejected]: (state, { payload }) => {
      state.successStoriesStatus = "failed";
      state.error = payload;
    },
    [GetAllSuccessStories.pending]: (state, { payload }) => {
      state.successStoriesStatus = "loading";
    },
    [GetAllSuccessStories.fulfilled]: (state, { payload }) => {
      state.successStoriesStatus = "success";
      state.allSuccessStories = payload;
    },
    [GetAllSuccessStories.rejected]: (state, { payload }) => {
      state.successStoriesStatus = "failed";
      state.error = payload;
    },
    [DeleteSuccessStories.pending]: (state, { payload }) => {
      state.successStoriesStatus = "loading";
    },
    [DeleteSuccessStories.fulfilled]: (state, { payload }) => {
      state.successStoriesStatus = "success";
      //   state.Businesss = payload;
    },
    [DeleteSuccessStories.rejected]: (state, { payload }) => {
      state.successStoriesStatus = "failed";
      state.error = payload;
    },
    [EditAddSuccessStory.pending]: (state, { payload }) => {
      state.successStoriesStatus = "loading";
    },
    [EditAddSuccessStory.fulfilled]: (state, { payload }) => {
      state.successStoriesStatus = "success";
    },
    [EditAddSuccessStory.rejected]: (state, { payload }) => {
      state.successStoriesStatus = "failed";
      state.error = payload;
    },
    [GetSingleSuccessStory.pending]: (state, { payload }) => {
      state.successStoriesStatus = "loading";
    },
    [GetSingleSuccessStory.fulfilled]: (state, { payload }) => {
      state.successStoriesStatus = "success";
      state.singleSuccessStory = payload;
    },
    [GetSingleSuccessStory.rejected]: (state, { payload }) => {
      state.successStoriesStatus = "failed";
      state.error = payload;
    },
    [AddSuccessStoryImages.pending]: (state, { payload }) => {
      state.successImagesStatus = "loading";
    },
    [AddSuccessStoryImages.fulfilled]: (state, { payload }) => {
      state.successImagesStatus = "success";
      state.singleService = payload;
    },
    [AddSuccessStoryImages.rejected]: (state, { payload }) => {
      state.successImagesStatus = "failed";
      state.error = payload;
    },
    [DeleteSuccessStoryImages.pending]: (state, { payload }) => {
      state.successImagesStatus = "loading";
    },
    [DeleteSuccessStoryImages.fulfilled]: (state, { payload }) => {
      state.successImagesStatus = "success";
      state.singleService = payload;
    },
    [DeleteSuccessStoryImages.rejected]: (state, { payload }) => {
      state.successImagesStatus = "failed";
      state.error = payload;
    },
  },
});

export const { SucessStoryReset } = SuccessStorySlice.actions;

export default SuccessStorySlice.reducer;
