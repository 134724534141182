import styled from "styled-components";
import { PlatStyle } from "./PlatFormsStyle";

export const TabsStyle = styled(PlatStyle)`
  .all-tabs {
    margin-bottom: 20px;
    position: relative;
    &:after {
      position: absolute;
      bottom: 0;
      content: "";
      left: 0;
      width: 100%;
      background-color: #879cf9;
      height: 3px;
    }
    ul {
      display: flex;
      justify-content: space-around;
      list-style: none;
      text-align: center;
      width: 100%;
      a {
        width: 100%;
        height: 100%;
      }
      li {
        width: 100%;
        font-size: 18px;
        padding: 0.5rem 0;
      }
      a.active {
        li {
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          background-color: #879cf9;
          color: white;
          font-weight: 600;
        }
      }
    }
  }
`;
