import styled from "styled-components";

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  text-align: left;
  th {
    font-size: 16px;
  }
  tr {
    border-bottom: 1px solid #f5f7f9;
  }
  td {
    padding-top: 12px;
    padding-bottom: 12px;
    padding-right: 10px;
    font-size: 14px;
  }

  svg {
    margin: 0 10px;
    color: #616161;
  }
`;
