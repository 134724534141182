import { Button } from "@mui/material";
import styled from "styled-components";

export const DeleteButton = styled(Button)`
  && {
    text-align: center;
    background-color: #c70000;
    color: white;
    font-size: 17px;
    font-weight: 500;
    height: 100%;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 7.31579px;
    transition: 0.5s all;
    font-weight: 600;
    margin-left: 20px;

    &:hover {
      background-color: #c70000;
      color: white;
      transform: translateY(-5px);
    }
  }
`;
