const mainUrl = "https://api.adgrouptech.com/api/v1";

export const login = `${mainUrl}/users/login`;

export const companies = `${mainUrl}/companies`;

export const pages = `${mainUrl}/pages`;

export const services = `${mainUrl}/services`;

export const services_images = `${mainUrl}/image-services`;

export const catalogue = `${mainUrl}/catalogue`;

export const success_story = `${mainUrl}/folios`;

export const success_story_images = `${mainUrl}/image-folios`;

export const partner = `${mainUrl}/partners`;
