import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import AllPlatforms from "./AllPlatforms";
const Platforms = () => {
  const location = useLocation();

  return (
    <>
      <Outlet />
    </>
  );
};

export default Platforms;
