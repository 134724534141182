import { Box, Modal, Typography } from "@mui/material";
import React from "react";
import { CancelButton } from "../../Layout/DeleteButton/CancelButton";
import { DeleteButton } from "../../Layout/DeleteButton/DeleteButton";
import { style } from "../../style/ModalStyle";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  DeleteCompany,
  GetAllCompanies,
} from "../../Redux/Slices/CompaniesSlice/CompaniesSlice";
import { useDispatch, useSelector } from "react-redux";

const ComapnyDelete = ({ openDelete, handleCloseDelete, selectedComany }) => {
  const dispatch = useDispatch();
  const { companiesStatus } = useSelector((state) => state?.companies);
  let senData = {
    title: selectedComany?.title,
    id: selectedComany?.id,
  };
  React.useEffect(() => {
    if (companiesStatus === "success") {
      handleCloseDelete();
    }
  }, [companiesStatus === "success"]);
  const handleDelete = async () => {
    await dispatch(DeleteCompany(senData));
    await dispatch(GetAllCompanies());
  };
  return (
    <div>
      <Modal
        open={openDelete}
        onClose={handleCloseDelete}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>
          <Typography id="keep-mounted-modal-title" variant="h6" component="h2">
            Are you sure you want to delete the paltform (
            {selectedComany?.title})
          </Typography>
          <Typography
            id="keep-mounted-modal-description"
            sx={{ mt: 5 }}
            align="center"
          >
            <CancelButton
              onClick={() => {
                handleCloseDelete();
              }}
            >
              Cancel
            </CancelButton>{" "}
            <DeleteButton onClick={handleDelete} endIcon={<DeleteIcon />}>
              Delete
            </DeleteButton>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

export default ComapnyDelete;
