import { Grid } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Layout/Loader/Loader";
import { GetAllSuccessStories } from "../../Redux/Slices/SuccessStories/SuccessStories";
import { PlatformPagesStyle } from "../../style/PlatformPagesStyle";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { Table } from "../../style/TableStyle";
import { GetAllCompanies } from "../../Redux/Slices/CompaniesSlice/CompaniesSlice";
import DeleteSuccessStory from "../Modals/DeleteSuccessStory";
import {
  GetAllPartner,
  partnerReset,
} from "../../Redux/Slices/PartnerSlice/Partner";
const Parnters = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();
  const { partnerStatus, allPartners } = useSelector((state) => state.partner);
  const { allCompanies } = useSelector((state) => state.companies);
  const [selected, setSelected] = React.useState();
  React.useEffect(() => {
    let functionApi = async () => {
      await dispatch(GetAllPartner());
      await dispatch(partnerReset());
      await dispatch(GetAllCompanies());
    };
    functionApi();
  }, [dispatch]);
  const findCompanyName = (id) => {
    let name = allCompanies?.find((e) => Number(e.id) === Number(id));
    return name;
  };
  return (
    <PlatformPagesStyle>
      <Grid container columns={12}>
        <Grid item xs={12}>
          <div className="middle">
            <div className="content">
              <Link to="create-partner">
                {" "}
                <button>Create new partner</button>
              </Link>
            </div>
          </div>
        </Grid>
        <div className="second">
          <div className="content">
            <Table>
              {partnerStatus === "loading" ? (
                <>
                  <Grid item xs={12}>
                    <Loader></Loader>
                  </Grid>
                </>
              ) : (
                <>
                  <thead>
                    <tr>
                      <th>Company Name</th>
                      <th style={{ textAlign: "center" }}>Title</th>
                      <th style={{ textAlign: "center" }}>Client</th>
                      <th style={{ textAlign: "center" }}>Web url</th>
                      <th style={{ textAlign: "center" }}>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allPartners?.map((e, i) => {
                      return (
                        <tr key={i}>
                          <td>{findCompanyName(e?.company_id)?.title}</td>
                          <td style={{ textAlign: "center" }}>{e?.title}</td>
                          <td style={{ textAlign: "center" }}>{e?.client}</td>
                          <td style={{ textAlign: "center" }}>
                            {" "}
                            <a href={e?.web_url} target="_blank">
                              Web url
                            </a>
                          </td>

                          <td style={{ textAlign: "center" }}>
                            <ModeEditIcon
                              onClick={async () => {
                                // await setSelectedPage(e);
                                // await navigate(`single-service-Edit/${e?.id}`);
                              }}
                            ></ModeEditIcon>
                            <DeleteIcon
                              onClick={() => {
                                setSelected(e);
                                handleOpen();
                              }}
                            ></DeleteIcon>
                            <VisibilityIcon
                              onClick={async () => {
                                // await setSelectedPage(e);
                                await navigate(
                                  `single-succes-stories/${e?.id}`
                                );
                              }}
                            ></VisibilityIcon>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </>
              )}
            </Table>
          </div>
        </div>
      </Grid>
      <Outlet></Outlet>
    </PlatformPagesStyle>
  );
};

export default Parnters;
