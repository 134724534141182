import { CircularProgress, Grid, TextField } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomizedProgressBars from "../Layout/Loader/Loader";
import { SubmitButton } from "../Layout/SubmitButton/SubmitButton";
import { authentication } from "../Redux/Slices/AuthSlice/authSlice";

const LoginForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loader = useSelector((state) => state.auth.status);
  const [data, setData] = React.useState();
  const handelChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    await dispatch(authentication(data));
    await navigate("/");
  };
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Grid container columns={12} p={10} spacing={5}>
          <Grid item xs={12}>
            <p
              style={{
                fontWeight: "800",
                textAlign: "center",
                fontSize: "28px",
              }}
            >
              Login
            </p>
          </Grid>
          <Grid item xs={12}>
            {" "}
            <TextField
              required
              id="outlined-required"
              label="Email"
              type="email"
              fullWidth
              name="email"
              onChange={handelChange}
            />
          </Grid>
          <Grid item xs={12}>
            {" "}
            <TextField
              id="outlined-disabled"
              label="Password"
              fullWidth
              type="password"
              onChange={handelChange}
              name="password"
            />
          </Grid>
          <Grid item xs={12}>
            {" "}
            <SubmitButton
              fullWidth
              endIcon={
                loader === "loading" ? (
                  <CircularProgress
                    style={{ color: "white" }}
                    size={20}
                    thickness={10}
                  />
                ) : (
                  <></>
                )
              }
              type="submit"
            >
              Submit
            </SubmitButton>
          </Grid>
        </Grid>{" "}
      </form>
    </div>
  );
};

export default LoginForm;
