import styled from "styled-components";
import { PlatStyle } from "./PlatFormsStyle";

export const PlatformPagesStyle = styled(PlatStyle)`

.middle{
    width: 100%;
}
.second{
    width:100%
}
`;
