import styled from "styled-components";
import SearchIcon from "@mui/icons-material/Search";
import { createTheme } from "@mui/material";

export const BigSection = styled.div`
  @import url("https://fonts.cdnfonts.com/css/disco-duck");
  h3 {
    font-family: "Disco Duck", sans-serif;
    font-family: "Disco Duck 3D", sans-serif;
    font-family: "Disco Duck Condensed", sans-serif;
    font-family: "Disco Duck Expanded", sans-serif;
    font-family: "Disco Duck 3D Italic", sans-serif;
    font-family: "Disco Duck ExpItalic", sans-serif;
    font-family: "Disco Duck Italic", sans-serif;
  }
  a {
    color: inherit;
    text-decoration: none;
  }
  a.active {
    li {
      div:nth-child(1) {
        background-color: #e4f1fd;
        border-radius: 6px;
        background-size: 300px 100px;
        div {
          svg {
            path {
              color: #7b99f5 !important;
            }
          }
        }
      }
      span {
        color: #7b99f5 !important;
      }
    }
  }
`;

export const ListStyle = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  p,
  span {
    font-family: "Inter", sans-serif !important;
    font-weight: 500;
    font-size: 16px;
    color: black !important;
  }

  img {
    width: 100%;
  }
  input[type="search"] {
    border: none;
    background: transparent;
    margin: 0;
    padding: 10px 14px;
    font-size: 14px;
    color: inherit;
    border: 1px solid transparent;
    border-radius: inherit;
    background: #ffffff;
    border: 1px solid #d0d5dd;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    width: 90%;
    margin-top: 0.3rem;
    margin-bottom: 0.5rem;
    background: url(${SearchIcon}) top left no-repeat;
  }
  input[type="search"]:focus {
    box-shadow: 0 0 3px 0 #ef7a49;
    border-color: #ef7a49;
    outline: none;
  }
  input[type="search"]::placeholder {
    color: #bbb;
  }
  .header {
    color: "black";
    font-weight: 500;
    font-size: 18px;
    color: #101828;
  }
  .des {
    font-weight: 400 !important;
    font-size: 14px !important;
    color: #667085 !important;
  }
`;
