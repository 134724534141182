import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AllPlatforms from "./components/AllPlatforms";
import Container from "./components/Container";
import EditSinglePage from "./components/EditSinglePage";
import EditSingleService from "./components/EditSingleService";
import PlatformPages from "./components/PlatformPages";
import Platforms from "./components/Platforms";
import PlatformServices from "./components/PlatformServices";
import CreatePage from "./components/CreatePage";
import SinglePlatform from "./components/SinglePlatform";
import CreateService from "./components/CreateService";
import HomeScreen from "./pages/HomeScreen";
import LoginPage from "./pages/LoginPage";
import { GlobalStyle } from "./style/GlobalStyle";
import SingleService from "./components/SingleService";
import AllSucessStories from "./components/SucessStories/AllSucessStories";
import CreateSuccessStory from "./components/SucessStories/CreateSuccessStory";
import SingleSuccessStory from "./components/SucessStories/SingleSuccessStory";
import Parnters from "./components/partner/Parnters";
import CreateParnter from "./components/partner/CreateParnter";

function App() {
  const isAutherized = useSelector((state) => state.auth?.data);

  return (
    <>
      <GlobalStyle></GlobalStyle>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {isAutherized ? (
        <>
          <Routes>
            <Route path="/" element={<Navigate to="/main/platforms" />}></Route>
            {/* <Route path="*" element={<Navigate to="/main" />} /> */}
            <Route path="/main" element={<HomeScreen />}>
              <Route path="platforms" element={<Platforms />}>
                <Route index element={<AllPlatforms />}></Route>
                <Route path="container" element={<Container />}>
                  <Route
                    path="single-paltform/:id"
                    element={<SinglePlatform></SinglePlatform>}
                  >
                    <Route path="pages">
                      <Route index element={<PlatformPages />}></Route>
                      <Route
                        path="create-page"
                        element={<CreatePage></CreatePage>}
                      ></Route>
                      <Route
                        path="single-page-Edit/:id"
                        element={<EditSinglePage></EditSinglePage>}
                      ></Route>
                    </Route>
                    <Route path="services">
                      <Route index element={<PlatformServices />}></Route>
                      <Route
                        path="create-service"
                        element={<CreateService></CreateService>}
                      ></Route>
                      <Route
                        path="single-service-Edit/:id"
                        element={<EditSingleService></EditSingleService>}
                      ></Route>
                      <Route
                        path="single-service/:id"
                        element={<SingleService></SingleService>}
                      ></Route>
                    </Route>
                    <Route path="succes-stories">
                      <Route index element={<AllSucessStories />}></Route>
                      <Route
                        path="create-succes-stories"
                        element={<CreateSuccessStory></CreateSuccessStory>}
                      ></Route>
                      {/* <Route
                        path="single-succes-stories-Edit/:id"
                        element={<EditSingleService></EditSingleService>}
                      ></Route> */}
                      <Route
                        path="single-succes-stories/:id"
                        element={<SingleSuccessStory></SingleSuccessStory>}
                      ></Route>
                    </Route>
                    <Route path="partners">
                      <Route index element={<Parnters />}></Route>
                      <Route
                        path="create-partner"
                        element={<CreateParnter></CreateParnter>}
                      ></Route>
                    </Route>
                  </Route>
                </Route>
              </Route>
            </Route>
          </Routes>
        </>
      ) : (
        <>
          <Routes>
            <Route path="/" element={<Navigate to="/login"></Navigate>}></Route>
            <Route path="*" element={<Navigate to="/login"></Navigate>}></Route>
            <Route path="/login" element={<LoginPage></LoginPage>}></Route>
          </Routes>
        </>
      )}
    </>
  );
}

export default App;
