import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  DeletePage,
  GetAllPages,
  saveData,
} from "../Redux/Slices/PagesSlice/PageSlice";
import { GetAllCompanies } from "../Redux/Slices/CompaniesSlice/CompaniesSlice";
import { PlatformPagesStyle } from "../style/PlatformPagesStyle";
import { Table } from "../style/TableStyle";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { Grid, Modal, Typography } from "@mui/material";
import Loader from "../Layout/Loader/Loader";
import { GetAllServices } from "../Redux/Slices/ServicesSlice/ServicesSlice";
const PlatformServices = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const id = location.pathname?.split("/")[5];
  const { allServices, servicesStatus, pagesData } = useSelector(
    (state) => state.services
  );
  const { allCompanies } = useSelector((state) => state.companies);

  const [selected, setSelectedPage] = React.useState();

  const [openDelete, setOpenDelete] = React.useState(false);
  const handleOpenDelete = () => setOpenDelete(true);
  const handleCloseDelete = () => setOpenDelete(false);

  React.useEffect(() => {
    let functionApi = async () => {
      await dispatch(GetAllServices(id));
      await dispatch(GetAllCompanies());
    };
    functionApi();
  }, []);
  const findCompanyName = (id) => {
    let name = allCompanies?.find((e) => Number(e.id) === Number(id));
    return name;
  };

  React.useEffect(() => {
    dispatch(saveData({ pageDetail: selected }));
  }, [selected]);
  const deletePage = () => {
    dispatch(DeletePage(selected?.id));
  };
  console.log("allCompanies", allCompanies);
  return (
    <>
      <PlatformPagesStyle>
        <Grid container columns={12}>
          <Grid item xs={12}>
            <div className="middle">
              <div className="content">
                <Link to="create-service">
                  {" "}
                  <button>Create service</button>
                </Link>
              </div>
            </div>
          </Grid>
          <div className="second">
            <div className="content">
              <Table>
                {servicesStatus === "loading" ? (
                  <>
                    <Grid item xs={12}>
                      <Loader></Loader>
                    </Grid>
                  </>
                ) : (
                  <>
                    <thead>
                      <tr>
                        <th>Company Name</th>
                        <th style={{ textAlign: "center" }}>Title</th>
                        {/* <th style={{ textAlign: "center" }}>Section</th> */}
                        <th style={{ textAlign: "center" }}>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {allServices?.map((e, i) => {
                        return (
                          <tr key={i}>
                            <td>{findCompanyName(e?.company_id)?.title}</td>
                            <td style={{ textAlign: "center" }}>{e?.title}</td>
                            {/* <td style={{ textAlign: "center" }}>
                              {e?.section}
                            </td> */}
                            <td style={{ textAlign: "center" }}>
                              <ModeEditIcon
                                onClick={async () => {
                                  await setSelectedPage(e);
                                  await navigate(
                                    `single-service-Edit/${e?.id}`
                                  );
                                }}
                              ></ModeEditIcon>
                              <DeleteIcon
                                onClick={() => {
                                  setSelectedPage(e);
                                  handleOpenDelete();
                                }}
                              ></DeleteIcon>
                              <VisibilityIcon
                                onClick={async () => {
                                  await setSelectedPage(e);
                                  await navigate(`single-service/${e?.id}`);
                                }}
                              ></VisibilityIcon>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </>
                )}
              </Table>
            </div>
          </div>
        </Grid>
        <Outlet></Outlet>
      </PlatformPagesStyle>
    </>
  );
};

export default PlatformServices;
