import { Modal, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { CancelButton } from "../../Layout/DeleteButton/CancelButton";
import { DeleteButton } from "../../Layout/DeleteButton/DeleteButton";
import {
  DeleteImageServices,
  GetSingleService,
  servicesReset,
} from "../../Redux/Slices/ServicesSlice/ServicesSlice";
import { style } from "../../style/ModalStyle";

const DeleteImage = ({ handleClose, open, selected }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  console.log("selected",selected)
  const deleteImage = async () => {
    await dispatch(DeleteImageServices(selected.id));
    await dispatch(GetSingleService(id));
    await dispatch(servicesReset());
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          align="center"
        >
          Are you sure you want to delete this image
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }} align="center">
          <CancelButton onClick={handleClose}>Cancel</CancelButton>{" "}
          <DeleteButton onClick={deleteImage}>Delete</DeleteButton>
        </Typography>
      </Box>
    </Modal>
  );
};

export default DeleteImage;
