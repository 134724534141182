import { configureStore } from "@reduxjs/toolkit";
import authSliceReducer from "../Slices/AuthSlice/authSlice";
import companyReducer from "../Slices/CompaniesSlice/CompaniesSlice";
import pagesReducer from "../Slices/PagesSlice/PageSlice";
import ServicesReducer from "../Slices/ServicesSlice/ServicesSlice";
import CatalogueReducer from "../Slices/Catalogue/Catalogue";
import SuccessStoryReducer from "../Slices/SuccessStories/SuccessStories";
import partnerRedicer from "../Slices/PartnerSlice/Partner";
export const store = configureStore({
  reducer: {
    auth: authSliceReducer,
    companies: companyReducer,
    pages: pagesReducer,
    services: ServicesReducer,
    catalogue: CatalogueReducer,
    sucessStory: SuccessStoryReducer,
    partner: partnerRedicer,
  },
});
