import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { companies } from "../../../Api/Api";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const GetAllCompanies = createAsyncThunk(
  "Company/GET",
  async (_, thunkAPI) => {
    try {
      var user = JSON.parse(localStorage.getItem("adGroup"));
      const headers = {
        Accept: "application/json",
        XMLHttpRequest: "XMLHttpRequest",
        Authorization: `Bearer ${user?.access_token}`,
      };
      return await axios({
        method: "GET",
        baseURL: `${companies}`,
        headers: headers,
      }).then((res) => {
        return res.data.data;
      });
    } catch (error) {
      const message = error.response.data.message;
      console.log("message", message);
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const AddCompany = createAsyncThunk(
  "company/create",
  async (data, thunkAPI) => {
    try {
      var user = JSON.parse(localStorage.getItem("adGroup"));
      console.log("user", user);
      const headers = {
        Accept: "application/json",
        XMLHttpRequest: "XMLHttpRequest",
        Authorization: `Bearer ${user?.access_token}`,
      };
      return await axios({
        method: "POST",
        baseURL: `${companies}`,
        headers: headers,
        data: data,
      }).then((res) => {
        toast.success(`Company has been Created`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return res.data;
      });
    } catch (error) {
      const message = error.response.data.message;
      console.log("message", message);
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const DeleteCompany = createAsyncThunk(
  "company/delete",
  async (data, thunkAPI) => {
    try {
      var user = JSON.parse(localStorage.getItem("adGroup"));
      const headers = {
        Accept: "application/json",
        XMLHttpRequest: "XMLHttpRequest",
        Authorization: `Bearer ${user?.access_token}`,
      };
      return await axios({
        method: "DELETE",
        baseURL: `${companies}/${data?.id}`,
        headers: headers,
        data: data,
      }).then((res) => {
        toast.success(`Company has been deleted`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return res.data;
      });
    } catch (error) {
      const message = error.response.data.message;
      console.log("message", message);
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const EditAddCompany = createAsyncThunk(
  "company/edit",
  async (data, thunkAPI) => {
    try {
      var user = JSON.parse(localStorage.getItem("adGroup"));
      console.log("data in request", data);
      const headers = {
        Accept: "application/json",
        XMLHttpRequest: "XMLHttpRequest",
        Authorization: `Bearer ${user?.access_token}`,
      };
      return await axios({
        method: "PATCH",
        baseURL: `${companies}/${data?.id}`,
        headers: headers,
        data: data,
      }).then((res) => {
        toast.success(`Company has been edited`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return res.data;
      });
    } catch (error) {
      const message = error.response.data.message;
      console.log("message", message);
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return thunkAPI.rejectWithValue(message);
    }
  }
);


const CompaniesSlice = createSlice({
  name: "Companies",
  initialState: {
    companiesStatus: "",
    error: "",
    allCompanies: [],
    Businesss: [],
  },
  reducers: {
    companiesReset: (state) => {
      state.companiesStatus = "";
    },
  },
  extraReducers: {
    [AddCompany.pending]: (state, { payload }) => {
      state.companiesStatus = "loading";
    },
    [AddCompany.fulfilled]: (state, { payload }) => {
      state.companiesStatus = "success";
    },
    [AddCompany.rejected]: (state, { payload }) => {
      state.companiesStatus = "failed";
      state.error = payload;
    },
    [GetAllCompanies.pending]: (state, { payload }) => {
      state.companiesStatus = "loading";
    },
    [GetAllCompanies.fulfilled]: (state, { payload }) => {
      state.companiesStatus = "success";
      state.allCompanies = payload;
    },
    [GetAllCompanies.rejected]: (state, { payload }) => {
      state.companiesStatus = "failed";
      state.error = payload;
    },
    [DeleteCompany.pending]: (state, { payload }) => {
      state.companiesStatus = "loading";
    },
    [DeleteCompany.fulfilled]: (state, { payload }) => {
      state.companiesStatus = "success";
      state.Businesss = payload;
    },
    [DeleteCompany.rejected]: (state, { payload }) => {
      state.companiesStatus = "failed";
      state.error = payload;
    },
    [EditAddCompany.pending]: (state, { payload }) => {
      state.companiesStatus = "loading";
    },
    [EditAddCompany.fulfilled]: (state, { payload }) => {
      state.companiesStatus = "success";
    },
    [EditAddCompany.rejected]: (state, { payload }) => {
      state.companiesStatus = "failed";
      state.error = payload;
    },
  },
});
export const { companiesReset } = CompaniesSlice.actions;

export default CompaniesSlice.reducer;
