import React from "react";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { SubmitButton } from "../Layout/SubmitButton/SubmitButton";
import { SinglePageStyle } from "../style/SinglePageStyle";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import {
  AddPage,
  GetSinglePage,
  pagesReset,
  EditPage,
} from "../Redux/Slices/PagesSlice/PageSlice";
import Loader from "../Layout/Loader/Loader";
import {
  EditService,
  GetSingleService,
  servicesReset,
} from "../Redux/Slices/ServicesSlice/ServicesSlice";
const EditSingleService = () => {
  const naigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { id } = useParams();
  const { allServices, servicesStatus, singleService } = useSelector(
    (state) => state.services
  );
  const arr = [
    {
      name: "Home",
      value: "home",
    },
    {
      name: "About",
      value: "about",
    },
    {
      name: "Why",
      value: "why",
    },
  ];
  let company_id = location.pathname.split("/")[5];
  let initialState = {
    title: singleService?.title,
    thumbnail_path: singleService?.thumbnail_path,
    description: singleService?.description,
    video_url: singleService?.video_url,
    subtitle: singleService?.subtitle,
    background_path: singleService?.background_path,
  };
  console.log("initialState", initialState);
  console.log("arr", arr);
  const [data, setData] = React.useState();
  console.log("data",data)
  React.useEffect(() => {
    setData(initialState);
  }, [singleService]);
  const goBack = () => {
    naigate(-1);
  };
  React.useEffect(() => {
    const fun = async () => {
      await dispatch(GetSingleService(id));
      await dispatch(servicesReset());
    };
    fun();
  }, [dispatch]);
  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setData({ ...data, [name]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("section", data?.section);
    formData.append("title", data?.title);
    formData.append("thumbnail_path", data?.thumbnail_path);
    formData.append("description", data?.description);
    formData.append("video_url", data?.video_url);
    formData.append("subtitle", data?.subtitle);
    formData.append("company_id", company_id);
    formData.append("_method", "PATCH");
    formData.append("background_path",data?.background_path)

    let sendData = {
      data: formData,
      id: id,
    };
    await dispatch(EditService(sendData));
    await dispatch(pagesReset());
  };
  return (
    <>
      <SinglePageStyle>
        <Grid container columns={12} mb={3}>
          <Grid item xs={12}>
            <SubmitButton onClick={() => naigate(-1)}>Go back</SubmitButton>
          </Grid>
        </Grid>
        <form onSubmit={handleSubmit}>
          <Grid container columns={12}>
            <div className="first">
              <div className="content">
                <Grid item xs={12}>
                  <h4>Page Form</h4>
                </Grid>
              </div>
            </div>
            <div className="middle">
              <div className="content">
                {allServices === "loading" ||
                !data?.title ||
                !data?.thumbnail_path ||
                servicesStatus === "loading" ? (
                  <>
                    <Loader></Loader>
                  </>
                ) : (
                  <>
                    {" "}
                    <Grid container columns={12} spacing={3}>
                      {/* <Grid item xs={12}>
                        <FormControl
                          fullWidth
                          required
                          sx={{ minWidth: 120 }}
                          size="small"
                        >
                          <InputLabel id="demo-simple-select-label">
                            Section
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            required
                            label="Section"
                            name="section"
                            onChange={handleChange}
                            value={data?.section}
                          >
                            {arr?.map((section) => (
                              <MenuItem
                                value={section.value}
                                key={section.value}
                              >
                                {section.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid> */}
                      <Grid item xs={12}>
                        <Button
                          variant="contained"
                          component="label"
                          fullWidth
                          startIcon={<PhotoCamera />}
                        >
                          Upload thumbnail path
                          <input
                            hidden
                            accept="image/*"
                            type="file"
                            onChange={(e) => {
                              setData({
                                ...data,
                                thumbnail_path: e.target.files[0],
                              });
                            }}
                          />
                          <span style={{ color: "white", margin: "0 10px" }}>
                            {" "}
                            {data?.thumbnail_path?.name}
                          </span>
                        </Button>
                        <a
                          style={{ color: "blue" }}
                          target="_blank"
                          href={data?.thumbnail_path}
                        >
                          Old Thumbnail path
                        </a>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          required
                          fullWidth
                          id="outlined-required"
                          label="Description"
                          size="small"
                          name="description"
                          onChange={handleChange}
                          value={data?.description}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          required
                          fullWidth
                          id="outlined-required"
                          label="Title"
                          size="small"
                          name="title"
                          onChange={handleChange}
                          value={data?.title}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          variant="contained"
                          component="label"
                          fullWidth
                          startIcon={<PhotoCamera />}
                        >
                          Upload background path
                          <input
                            hidden
                            accept="image/*"
                            type="file"
                            onChange={(e) => {
                              setData({
                                ...data,
                                background_path: e.target.files[0],
                              });
                            }}
                          />
                          <span style={{ color: "white", margin: "0 10px" }}>
                            {" "}
                            {data?.background_path?.name}
                          </span>
                        </Button>
                        <a
                          style={{ color: "blue" }}
                          target="_blank"
                          href={data?.background_path}
                        >
                          Old Thumbnail path
                        </a>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          required
                          fullWidth
                          id="outlined-required"
                          label="video url"
                          size="small"
                          name="video_url"
                          onChange={handleChange}
                          value={data?.video_url}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <SubmitButton type="submit" fullWidth>
                          Submit
                        </SubmitButton>
                      </Grid>
                    </Grid>
                  </>
                )}
              </div>
            </div>
          </Grid>
        </form>
      </SinglePageStyle>
    </>
  );
};

export default EditSingleService;
