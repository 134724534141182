import styled from "styled-components";

export const PlatStyle = styled.div`
  .first {
    background-color: #ffffff;
    border-top-right-radius: 10.9737px;
    border-top-left-radius: 10.9737px;
    padding: 1rem 0;
    border: 0.914474px solid #d0d5dd;
    box-shadow: 0px 0.914474px 1.82895px rgba(16, 24, 40, 0.05);
    .content {
      margin: 0 15px;
    }
  }
  .middle {
    background-color: #ffffff;
    /* border-top-right-radius: 10.9737px; */
    /* border-top-left-radius: 10.9737px; */
    padding: 1rem 0;
    border: 0.914474px solid #d0d5dd;
    box-shadow: 0px 0.914474px 1.82895px rgba(16, 24, 40, 0.05);
    .content {
      margin: 0 15px;
      span {
        font-size: 16px;
        font-weight: 700;
        color: #ef7a4a;
      }
    }
  }
  .second {
    border: 0.914474px solid #d0d5dd;
    box-shadow: 0px 0.914474px 1.82895px rgba(16, 24, 40, 0.05);
    border-bottom-right-radius: 10.9737px;
    border-bottom-left-radius: 10.9737px;
    padding: 1rem 0;
    background-color: #ffffff;
    .content {
      margin: 0 15px;
    }
  }
  svg {
    cursor: pointer;
  }
  form {
    .space {
      margin-right: 10px;
    }
    @media (max-width: 930px) {
      span:nth-child(2) {
        /* margin: 10px 0; */
      }
    }
  }
`;
