export const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 900,
  // bgcolor: "background.paper",
  // border: "2px solid #000",
  // boxShadow: 24,
  border:"none",
  p: 4,
  background: "white"
};
