import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { SubmitButton } from "../Layout/SubmitButton/SubmitButton";
import { SinglePageStyle } from "../style/SinglePageStyle";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { AddPage, pagesReset } from "../Redux/Slices/PagesSlice/PageSlice";
import Loader from "../Layout/Loader/Loader";
const CreatePage = () => {
  const naigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { allPages, pagesStatus, pagesData } = useSelector(
    (state) => state.pages
  );
  const arr = [
    {
      name: "Home",
      value: "home",
    },
    {
      name: "About",
      value: "about",
    },
    {
      name: "Why",
      value: "why",
    },
  ];
  let company_id = location.pathname.split("/")[5];
  const [data, setData] = React.useState();
  const goBack = () => {
    naigate(-1);
  };
  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setData({ ...data, [name]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("section", data?.section);
    formData.append("title", data?.title);
    formData.append("thumbnail_path", data?.thumbnail_path);
    formData.append("description", data?.description);
    formData.append("video_url", data?.video_url);
    formData.append("subtitle", data?.subtitle);
    formData.append("company_id", company_id);
    await dispatch(AddPage(formData));
    await dispatch(pagesReset());
  };
  console.log("data", data);
  return (
    <SinglePageStyle>
      <form onSubmit={handleSubmit}>
        <Grid container columns={12}>
          <div className="middle">
            <div className="content">
              <Grid item xs={12}>
                <h4>Page Form</h4>
              </Grid>
            </div>
          </div>
          <div className="second">
            <div className="content">
              {pagesStatus === "loading" ? (
                <>
                  <Loader></Loader>
                </>
              ) : (
                <>
                  {" "}
                  <Grid container columns={12} spacing={3}>
                    <Grid item xs={12}>
                      <FormControl
                        fullWidth
                        required
                        sx={{ minWidth: 120 }}
                        size="small"
                      >
                        <InputLabel id="demo-simple-select-label">
                          Section
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          required
                          label="Section"
                          name="section"
                          onChange={handleChange}
                        >
                          {arr?.map((section) => (
                            <MenuItem value={section.value} key={section.value}>
                              {section.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        component="label"
                        fullWidth
                        startIcon={<PhotoCamera />}
                      >
                        Upload
                        <input
                          hidden
                          accept="image/*"
                          type="file"
                          onChange={(e) => {
                            setData({
                              ...data,
                              thumbnail_path: e.target.files[0],
                            });
                          }}
                        />
                        <span style={{ color: "white", margin: "0 10px" }}>
                          {" "}
                          {data?.thumbnail_path?.name}
                        </span>
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        required
                        fullWidth
                        id="outlined-required"
                        label="Description"
                        size="small"
                        name="description"
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        required
                        fullWidth
                        id="outlined-required"
                        label="Title"
                        size="small"
                        name="title"
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        required
                        fullWidth
                        id="outlined-required"
                        label="video url"
                        size="small"
                        name="video_url"
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        required
                        fullWidth
                        id="outlined-required"
                        label="Subtitle"
                        size="small"
                        name="subtitle"
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <SubmitButton type="submit" fullWidth>
                        Submit
                      </SubmitButton>
                    </Grid>
                  </Grid>
                </>
              )}
            </div>
          </div>
        </Grid>
      </form>
    </SinglePageStyle>
  );
};

export default CreatePage;
