import { Grid } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { SubmitButton } from "../Layout/SubmitButton/SubmitButton";
import Tabs from "../Layout/Tabs/Tabs";
import { saveData } from "../Redux/Slices/PagesSlice/PageSlice";

const SinglePlatform = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { id } = useParams();
  const goBack = () => {
    navigate("/main/platforms");
  };

  return (
    <div>
      <Grid container columns={12}>
        {location?.pathname.includes("single-page") ||
        location?.pathname.includes("single-service") ||
        location?.pathname.includes("create-page") ||
        location?.pathname.includes("create-service") ||
        location?.pathname.includes("create-succes-stories") ||
        location?.pathname.includes("single-succes-stories") ||
        location?.pathname.includes("create-partner") ? (
          <></>
        ) : (
          <>
            {" "}
            <Grid item xs={12} mb={3}>
              <SubmitButton onClick={goBack}>Go Back</SubmitButton>
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          <div className="first">
            <div className="content">
              <Tabs></Tabs>
            </div>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className="middle">
            <p className="content">
              <Outlet></Outlet>
            </p>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default SinglePlatform;
