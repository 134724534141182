import { Modal, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useDispatch } from "react-redux";
import { CancelButton } from "../../Layout/DeleteButton/CancelButton";
import { DeleteButton } from "../../Layout/DeleteButton/DeleteButton";
import {
  DeleteSuccessStories,
  GetAllSuccessStories,
} from "../../Redux/Slices/SuccessStories/SuccessStories";
import { style } from "../../style/ModalStyle";

const DeleteSuccessStory = ({ open, handleClose, selected }) => {
  const dispatch = useDispatch();
  const deleteSuccess = async () => {
    await dispatch(DeleteSuccessStories(selected?.id));
    await dispatch(GetAllSuccessStories());
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          align="center"
        >
          Are you sure you want to delete this success story
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }} align="center">
          <CancelButton onClick={handleClose}>Cancel</CancelButton>{" "}
          <DeleteButton onClick={deleteSuccess}>Delete</DeleteButton>
        </Typography>
      </Box>
    </Modal>
  );
};

export default DeleteSuccessStory;
