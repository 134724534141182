import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { partner } from "../../../Api/Api";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const GetAllPartner = createAsyncThunk(
  "Partner/GET",
  async (_, thunkAPI) => {
    try {
      var user = JSON.parse(localStorage.getItem("adGroup"));
      const headers = {
        Accept: "application/json",
        XMLHttpRequest: "XMLHttpRequest",
        Authorization: `Bearer ${user?.access_token}`,
      };
      return await axios({
        method: "GET",
        baseURL: `${partner}`,
        headers: headers,
      }).then((res) => {
        return res.data.data;
      });
    } catch (error) {
      const message = error.response.data.message;
      console.log("message", message);
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const AddPartner = createAsyncThunk(
  "partner/create",
  async (data, thunkAPI) => {
    try {
      var user = JSON.parse(localStorage.getItem("adGroup"));
      console.log("user", user);
      const headers = {
        Accept: "application/json",
        XMLHttpRequest: "XMLHttpRequest",
        Authorization: `Bearer ${user?.access_token}`,
      };
      return await axios({
        method: "POST",
        baseURL: `${partner}`,
        headers: headers,
        data: data,
      }).then((res) => {
        toast.success(`Partner has been Created`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return res.data;
      });
    } catch (error) {
      const message = error.response.data.message;
      console.log("message", message);
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const DeletePartner = createAsyncThunk(
  "partner/delete",
  async (data, thunkAPI) => {
    try {
      var user = JSON.parse(localStorage.getItem("adGroup"));
      const headers = {
        Accept: "application/json",
        XMLHttpRequest: "XMLHttpRequest",
        Authorization: `Bearer ${user?.access_token}`,
      };
      return await axios({
        method: "DELETE",
        baseURL: `${partner}/${data}`,
        headers: headers,
        // data: data,
      }).then((res) => {
        toast.success(`Partner has been deleted`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return res.data;
      });
    } catch (error) {
      const message = error.response.data.message;
      console.log("message", message);
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const EditPartner = createAsyncThunk(
  "partner/edit",
  async (data, thunkAPI) => {
    try {
      var user = JSON.parse(localStorage.getItem("adGroup"));
      console.log("data in request", data);
      const headers = {
        Accept: "application/json",
        XMLHttpRequest: "XMLHttpRequest",
        Authorization: `Bearer ${user?.access_token}`,
      };
      return await axios({
        method: "PATCH",
        baseURL: `${partner}/${data?.id}`,
        headers: headers,
        data: data,
      }).then((res) => {
        toast.success(`Partner has been edited`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return res.data;
      });
    } catch (error) {
      const message = error.response.data.message;
      console.log("message", message);
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const PartnerSlice = createSlice({
  name: "Partner",
  initialState: {
    partnerStatus: "",
    error: "",
    allPartners: [],
    Businesss: [],
  },
  reducers: {
    partnerReset: (state) => {
      state.partnerStatus = "";
    },
  },
  extraReducers: {
    [AddPartner.pending]: (state, { payload }) => {
      state.partnerStatus = "loading";
    },
    [AddPartner.fulfilled]: (state, { payload }) => {
      state.partnerStatus = "success";
    },
    [AddPartner.rejected]: (state, { payload }) => {
      state.partnerStatus = "failed";
      state.error = payload;
    },
    [GetAllPartner.pending]: (state, { payload }) => {
      state.partnerStatus = "loading";
    },
    [GetAllPartner.fulfilled]: (state, { payload }) => {
      state.partnerStatus = "success";
      state.allPartners = payload;
    },
    [GetAllPartner.rejected]: (state, { payload }) => {
      state.partnerStatus = "failed";
      state.error = payload;
    },
    [DeletePartner.pending]: (state, { payload }) => {
      state.partnerStatus = "loading";
    },
    [DeletePartner.fulfilled]: (state, { payload }) => {
      state.partnerStatus = "success";
      state.Businesss = payload;
    },
    [DeletePartner.rejected]: (state, { payload }) => {
      state.partnerStatus = "failed";
      state.error = payload;
    },
    [EditPartner.pending]: (state, { payload }) => {
      state.partnerStatus = "loading";
    },
    [EditPartner.fulfilled]: (state, { payload }) => {
      state.partnerStatus = "success";
    },
    [EditPartner.rejected]: (state, { payload }) => {
      state.partnerStatus = "failed";
      state.error = payload;
    },
  },
});

export const { partnerReset } = PartnerSlice.actions;

export default PartnerSlice.reducer;
