import { CircularProgress, Grid, IconButton } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Loader from "../../Layout/Loader/Loader";
import { GetAllCompanies } from "../../Redux/Slices/CompaniesSlice/CompaniesSlice";
import {
  AddSuccessStoryImages,
  GetSingleSuccessStory,
  SucessStoryReset,
} from "../../Redux/Slices/SuccessStories/SuccessStories";
import { SinglePageStyle } from "../../style/SinglePageStyle";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteImageSuccess from "../Modals/DeleteImageSuccess";
const SingleSuccessStory = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  React.useEffect(() => {
    dispatch(GetSingleSuccessStory(id));
    dispatch(GetAllCompanies());
  }, []);
  const { successStoriesStatus, singleSuccessStory, successImagesStatus } =
    useSelector((state) => state?.sucessStory);
  const { allCompanies } = useSelector((state) => state?.companies);
  const [uploadedFiles, setUploadedFiles] = React.useState([]);
  const [fileLimit, setFileLimit] = React.useState(false);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [selected, setSelected] = React.useState();

  React.useEffect(() => {
    if (successImagesStatus === "success") {
      setUploadedFiles([]);
    }
  }, [successImagesStatus]);

  let MAX_COUNT = 7;
  const handleUploadFiles = (files) => {
    const uploaded = [...uploadedFiles];
    let limitExceeded = false;
    files.some((file) => {
      if (uploaded.findIndex((f) => f.name === file.name) === -1) {
        uploaded.push(file);
        if (uploaded.length === MAX_COUNT) setFileLimit(true);
        if (uploaded.length > MAX_COUNT) {
          alert(`You can only add a maximum of ${MAX_COUNT} files`);
          setFileLimit(false);
          limitExceeded = true;
          return true;
        }
      }
    });
    if (!limitExceeded) setUploadedFiles(uploaded);
  };

  const handleFileEvent = (e) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    handleUploadFiles(chosenFiles);
  };
  const deleteImage = (index) => {
    let newArr = [...uploadedFiles];
    newArr.splice(index, 1);
    setUploadedFiles(newArr);
  };
  const findCompany = (id) => {
    let comany = allCompanies.find((e) => e.id === id);
    return comany;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (uploadedFiles.length > 0) {
      const formData = new FormData();
      formData.append("folio_id", id);
      uploadedFiles.map((e, i) => {
        formData.append(`image_path[${i}]`, e);
      });
      await dispatch(AddSuccessStoryImages(formData));
      await dispatch(GetSingleSuccessStory(id));
      await dispatch(SucessStoryReset());
    }
  };
  return (
    <SinglePageStyle>
      {successStoriesStatus === "loading" ||
      successImagesStatus === "loading" ? (
        <>
          <Loader></Loader>
        </>
      ) : (
        <>
          <Grid container columns={12}>
            <Grid item xs={12}>
              <div className="first">
                <div className="content">
                  <h3>Single Story-success</h3>
                </div>
              </div>
              <div className="middle">
                <div className="content">
                  <Grid container columns={12} spacing={2}>
                    <Grid item xs={4}>
                      <p className="key">
                        Company name:{" "}
                        <span className="value">
                          {findCompany(singleSuccessStory?.company_id)?.title}
                        </span>
                      </p>
                    </Grid>
                    <Grid item xs={4}>
                      <p className="key">
                        Title:{" "}
                        <span className="value">
                          {singleSuccessStory?.title}
                        </span>
                      </p>
                    </Grid>
                    <Grid item xs={12}>
                      <p className="key">
                        Description:{" "}
                        <span className="value">
                          {singleSuccessStory?.description}
                        </span>
                      </p>
                    </Grid>
                    <Grid item xs={4}>
                      <p className="key">
                        Thumbnail path:{" "}
                        <span className="value">
                          <Zoom>
                            <img
                              alt="That Wanaka Tree, New Zealand by Laura Smetsers"
                              src={singleSuccessStory?.thumbnail_path}
                              width="50"
                            />
                          </Zoom>
                        </span>
                      </p>
                    </Grid>

                    <Grid item xs={4}>
                      <p className="key">
                        Video url:{" "}
                        <span className="value">
                          <a
                            href={singleSuccessStory?.video_url}
                            target="_blank"
                          >
                            Video url
                          </a>
                        </span>
                      </p>
                    </Grid>
                    <Grid item xs={4}>
                      <p className="key">
                        Client:{" "}
                        <span className="value">
                          {singleSuccessStory?.client}
                        </span>
                      </p>
                    </Grid>
                    <Grid item xs={4}>
                      <p className="key">
                        Web url:{" "}
                        <span className="value">
                          <a href={singleSuccessStory?.web_url} target="_blank">
                            Web url
                          </a>
                        </span>
                      </p>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className="middle">
                <div className="content">
                  <div className="container">
                    <h4>Images services</h4>
                    <form onSubmit={handleSubmit}>
                      <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="label"
                      >
                        <input
                          hidden
                          accept="image/*"
                          type="file"
                          multiple
                          onChange={handleFileEvent}
                          disabled={fileLimit}
                        />
                        <AddIcon></AddIcon>
                      </IconButton>
                      <button type="submit">Upload Images</button>
                    </form>
                    {successImagesStatus === "loading" ? (
                      <>
                        {" "}
                        <CircularProgress
                          style={{ padding: "10px" }}
                        ></CircularProgress>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="uploaded-files-list">
                    {uploadedFiles.map((file, i) => (
                      <div key={i}>
                        {file.name}{" "}
                        <IconButton aria-label="delete">
                          <DeleteIcon
                            onClick={() => {
                              deleteImage(i);
                            }}
                          />
                        </IconButton>
                      </div>
                    ))}
                  </div>

                  <div className="images">
                    {singleSuccessStory?.image_folios?.map((img, i) => {
                      return (
                        <Zoom key={i}>
                          <img src={img?.image_path} width="300" />
                          <button
                            className="delete-button"
                            onClick={() => {
                              setSelected(img);
                              handleOpen();
                            }}
                          >
                            <DeleteIcon></DeleteIcon>
                          </button>
                        </Zoom>
                      );
                    })}
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </>
      )}
      <DeleteImageSuccess
        open={open}
        handleOpen={handleOpen}
        handleClose={handleClose}
        selected={selected}
      ></DeleteImageSuccess>
    </SinglePageStyle>
  );
};

export default SingleSuccessStory;
